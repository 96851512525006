export { default as ArrowDownSvg } from './arrow-down.svg';
export { default as ArrowLeftSvg } from './arrow-left.svg';
export { default as ArrowRightSvg } from './arrow-right.svg';
export { default as CalendarSvg } from './calendar.svg';
export { default as ChevronRightSvg } from './chevron-right.svg';
export { default as EyeSlashSvg } from './eye-slash.svg';
export { default as EyeSvg } from './eye.svg';
export { default as KeySvg } from './key.svg';
export { default as MailSvg } from './mail.svg';
export { default as MessagesSvg } from './messages.svg';
export { default as SearchSvg } from './search.svg';
export { default as UserSvg } from './user.svg';
